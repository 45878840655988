"use strict";

var _interopRequireDefault = require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("E:\\zy\\yidahanggaotie\\h5\\node_modules\\_core-js@2.6.12@core-js\\modules\\es6.array.iterator.js");
require("E:\\zy\\yidahanggaotie\\h5\\node_modules\\_core-js@2.6.12@core-js\\modules\\es6.promise.js");
require("E:\\zy\\yidahanggaotie\\h5\\node_modules\\_core-js@2.6.12@core-js\\modules\\es6.object.assign.js");
require("E:\\zy\\yidahanggaotie\\h5\\node_modules\\_core-js@2.6.12@core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App.vue"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _vant = require("vant");
require("vant/lib/index.css");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
require("@/styles/index.scss");
require("@/icons/");
var filters = _interopRequireWildcard(require("./filters"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// global css
// icon
// import * as Sentry from '@sentry/browser'
// import { Vue as VueIntegration } from '@sentry/integrations'

// global filters

_vue.default.use(_vant.Button);
_vue.default.use(_vant.Image);
_vue.default.use(_vant.Lazyload);
_vue.default.use(_vant.Toast);
_vue.default.use(_vant.Popup);
_vue.default.use(_vant.Form);
_vue.default.use(_vant.Field);
_vue.default.use(_vant.CountDown);
_vue.default.use(_vant.Dialog);
_vue.default.use(_vant.Swipe);
_vue.default.use(_vant.SwipeItem);
_vue.default.use(_vant.List);
_vue.default.use(_vant.Col);
_vue.default.use(_vant.Row);
_vue.default.use(_vant.Progress);
_vue.default.use(_vant.Cell);
_vue.default.use(_vant.Overlay);
_vue.default.use(_vant.Tag);
_vue.default.use(_vant.Loading);
_vue.default.use(_vant.Stepper);
_vue.default.use(_vant.NoticeBar);
_vue.default.use(_vueClipboard.default);

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.GLOBAL = {
  // 全局变量
  defaultImg: require('@/assets/default.png'),
  // 默认图片
  defaultRectangleImg: require('@/assets/default-rectangle.png'),
  // 默认长方形图片
  defaultPoster: require('@/assets/default-poster.png'),
  // 默认海报
  appId: 'wx2a02e54f2c6cba16' // 微信appid, 用于授权
};
_vue.default.config.productionTip = false;

// 忽略自定义元素标签抛出的报错
_vue.default.config.ignoredElements = ['wx-open-launch-app'];
new _vue.default({
  store: _store.default,
  router: _router.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');