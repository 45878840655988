"use strict";

var _interopRequireDefault = require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMobile = getMobile;
exports.getToken = getToken;
exports.removeMobile = removeMobile;
exports.removeToken = removeToken;
exports.setMobile = setMobile;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'battlespace-webview-' + process.env.NODE_ENV + '-Token';
// const in30Minutes = 1 / 48 // 30分钟
var expiresDay = 30;
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token, {
    expires: expiresDay
  });
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey, {
    expires: expiresDay
  });
}
function getMobile() {
  return _jsCookie.default.get('mobile');
}
function setMobile(token) {
  return _jsCookie.default.set('mobile', token, {
    expires: expiresDay
  });
}
function removeMobile() {
  return _jsCookie.default.remove('mobile', {
    expires: expiresDay
  });
}