"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "reservation-bg"
  }, [_c("div", {
    staticClass: "reservation-bottom"
  }, [!_vm.isWeixin ? _c("a", {
    attrs: {
      href: "weixin://dl/business/?appid=wx626619f74a28db48&path=pages/myReservation/jhReservation&query=cstID=" + _vm.cstID + "&env_version=" + _vm.ENV_VERSION
    }
  }, [_c("div", {
    staticClass: "reservation-bottom-btn"
  }, [_vm._v("前往下单")])]) : _c("wx-open-launch-weapp", {
    attrs: {
      id: "launch-btn",
      appid: "wx626619f74a28db48",
      username: "gh_dffbf9b4f164",
      path: "pages/myReservation/jhReservation?cstID=" + _vm.cstID + "&env_version=" + _vm.ENV_VERSION
    }
  }, [[_c("div", {
    staticClass: "reservation-bottom-btn"
  }, [_vm._v("前往下单")])]], 2), _c("div", {
    staticClass: "reservation-bottom-rule",
    on: {
      click: function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v(" 查看服务规则 ")])], 1), _c("van-popup", {
    attrs: {
      "overlay-style": {
        backgroundColor: "rgba(0, 0, 0, 0.51)"
      }
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c("div", {
    staticClass: "rule"
  }, [_c("div", {
    staticClass: "rule-title"
  }, [_vm._v("服务细则")]), _c("div", {
    staticClass: "rule-content"
  }, [_vm._v(" 1.专用安检通道及前台验证验票服 务;"), _c("br"), _vm._v(" 2.厅内专设自助取票机;"), _c("br"), _vm._v(" 3.提供贵宾室休息，自助茶点;"), _c("br"), _vm._v(" 4.手机 wif 上网和自带电脑宽带接口上网;"), _c("br"), _vm._v(" 5.高清大屏电视，悦享视听娱乐;"), _c("br"), _vm._v(" 6.提供列车信息查询，乘车提醒;"), _c("br"), _vm._v(" 7.报刊杂志;"), _c("br"), _vm._v(" 8.提供专人送至检票口/车厢门口"), _c("br"), _c("br"), _vm._v(" 温馨提示:一码一人一次 ")]), _c("div", {
    staticClass: "close"
  }, [_c("div", {
    staticClass: "rule-btn",
    on: {
      click: function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v("关闭")])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;