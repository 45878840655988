"use strict";

var _interopRequireDefault = require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteAction = deleteAction;
exports.getAction = getAction;
exports.postAction = postAction;
exports.putAction = putAction;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * @author dengwenyan
 * @email dengwenyan0126@outlook.com
 * @date 2024/7/10
 * @Description: 通用接口
*/

/**
 * 删除
 * @param url
 */
function deleteAction(url) {
  return (0, _request.default)({
    url: url,
    method: 'delete'
  });
}

/**
 * 获取 get
 * @param url
 * @param params
 * @returns {*}
 */
function getAction(url, params) {
  return (0, _request.default)({
    url: url,
    method: 'get',
    params: params
  });
}

/**
 * post
 * @param url
 * @param params
 * @returns {*}
 */
function postAction(url, params) {
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: params
  });
}

/**
 * put
 * @param url
 * @param params
 * @returns {*}
 */
function putAction(url, params) {
  return (0, _request.default)({
    url: url,
    method: 'put',
    data: params
  });
}