"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "home"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "cont"
    }, [_c("div", {
      staticClass: "cont-fl"
    }, [_c("img", {
      attrs: {
        src: item.coverImg && item.coverImg.url,
        mode: ""
      }
    })]), _c("div", {
      staticClass: "cont-fr"
    }, [_c("div", {
      staticClass: "cont-ka"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "cont-fw"
    }, [item.vipService ? _c("span", [_vm._v("贵宾服务")]) : _vm._e(), item.vipService && item.stressService ? _c("span", [_vm._v("/")]) : _vm._e(), item.stressService ? _c("span", [_vm._v("要客服务")]) : _vm._e()]), _c("div", {
      staticClass: "cont-dete"
    }, [_c("span", {
      staticClass: "cont-dete-span"
    }, [_vm._v("有效期：")]), item.termOfValidity === "unlimited" ? _c("span", {
      staticClass: "cont-dete-time"
    }, [_vm._v("不限")]) : _c("span", {
      staticClass: "cont-dete-time"
    }, [_vm._v("1年")])]), _c("div", {
      staticClass: "cont-money"
    }, [_c("div", {
      staticClass: "cont-money-icon"
    }, [_c("span", {
      staticClass: "cont-money-my"
    }, [_vm._v("￥")]), _c("span", {
      staticClass: "cont-money-num"
    }, [_vm._v(_vm._s(item.price))])]), !_vm.isWeixin ? _c("a", {
      attrs: {
        href: "weixin://dl/business/?appid=wx626619f74a28db48&path=pages/rights/view&query=id%3D" + item.id
      }
    }, [_c("div", {
      staticClass: "cont-money-btn"
    }, [_vm._v("购买")])]) : _c("wx-open-launch-weapp", {
      attrs: {
        id: "launch-btn",
        appid: "wx626619f74a28db48",
        username: "gh_dffbf9b4f164",
        path: "pages/rights/view?id=" + item.id
      }
    }, [[_c("div", {
      staticClass: "cont-money-btn"
    }, [_vm._v("购买")])]], 2)], 1)])]);
  }), 0), _c("div", {
    staticStyle: {
      height: "150px",
      width: "1px"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;