"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'App',
  created: function created() {
    // if (process.env.NODE_ENV === 'production') {
    //   const head = document.head || document.getElementsByTagName('head')[0]
    //   const mate = document.createElement('meta')
    //   mate.setAttribute('http-equiv', 'Content-Security-Policy')
    //   mate.setAttribute('content', 'upgrade-insecure-requests')
    //   head.appendChild(mate)
    // }
  }
};