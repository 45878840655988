"use strict";

var _interopRequireDefault = require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _vant = require("vant");
var _auth = require("@/utils/auth");
var _auth2 = require("./auth");
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API_URL + process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  config.headers['x-client'] = 2;
  if ((0, _auth.getToken)() !== 'null') {
    config.headers['x-token'] = (0, _auth.getToken)() || '';
    // config.headers['x-token'] = 'df676a4bd853cd278ad9d72e96d494ef'
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0) {
    if (res.code === 20001) {
      // 用户未登录，清除token
      (0, _auth2.setToken)('');
      location.reload();
    }
    (0, _vant.Toast)(res.msg || 'Error');
    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  (0, _vant.Toast)(error.message || 'Error');
  return Promise.reject(error);
});
var _default = exports.default = service;