// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/h5-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-0009723b] .van-popup {\n  border-radius: 8px;\n}\n.reservation-bg[data-v-0009723b] {\n  width: 100vw;\n  height: 100vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: contain;\n}\n.reservation-bg .reservation-bottom[data-v-0009723b] {\n    width: 100%;\n    position: fixed;\n    bottom: 110px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 0 15px;\n}\n.reservation-bg .reservation-bottom .reservation-bottom-btn[data-v-0009723b] {\n      width: 230px;\n      height: 40px;\n      line-height: 40px;\n      font-weight: 400;\n      font-size: 15px;\n      color: #ffffff;\n      background: #af1e24;\n      border-radius: 20px;\n      text-align: center;\n}\n.reservation-bg .reservation-bottom .reservation-bottom-rule[data-v-0009723b] {\n      margin-top: 18px;\n      color: #929292;\n      line-height: 21px;\n      text-align: left;\n      font-style: normal;\n      -webkit-text-decoration-line: underline;\n              text-decoration-line: underline;\n}\n.reservation-bg .rule[data-v-0009723b] {\n    width: 294px;\n    height: 434px;\n}\n.reservation-bg .rule .rule-title[data-v-0009723b] {\n      text-align: center;\n      margin: 16px auto 12px;\n      height: 24px;\n      font-weight: 600;\n      font-size: 17px;\n      color: #000000;\n}\n.reservation-bg .rule .rule-content[data-v-0009723b] {\n      width: 254px;\n      height: 288px;\n      margin: 0 20px;\n      font-family: PingFangSC, PingFang SC;\n      font-weight: 400;\n      font-size: 16px;\n      color: #000000;\n      line-height: 24px;\n      text-align: left;\n      font-style: normal;\n}\n.reservation-bg .rule .close[data-v-0009723b] {\n      width: 84px;\n      height: 40px;\n      line-height: 40px;\n      text-align: center;\n      margin: 24px auto;\n      border-radius: 20px;\n      border: 1px solid #979797;\n}\n", ""]);
// Exports
module.exports = exports;
