"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Error404',
  data: function data() {
    return {
      bgImg: require('@/assets/page-shading.png'),
      errorIcon: require('@/assets/error.png')
    };
  }
};