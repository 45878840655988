"use strict";

var _interopRequireDefault = require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTime = formatTime;
exports.getTimeDiff = getTimeDiff;
exports.isAndroid = isAndroid;
exports.isIOS = isIOS;
exports.parseTime = parseTime;
exports.requestData = requestData;
exports.setupWebViewJavascriptBridge = setupWebViewJavascriptBridge;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/typeof.js"));
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */

function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  if (!time) {
    return '-';
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * 计算两个时间相差多少天多少小时多少分多少秒
 * @param {Object} endDate
 * @param {Object} beginDate
 */
function getTimeDiff(endDate, beginDate) {
  var obj = {};
  var diff = endDate - beginDate; // 时间戳相减

  obj.diff = diff;
  // 计算出相差天数
  obj.day = Math.floor(diff / (24 * 3600 * 1000));

  // 计算出小时数
  var leave1 = diff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  obj.hour = Math.floor(leave1 / (3600 * 1000));
  // 计算相差分钟数
  var leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  obj.minute = Math.floor(leave2 / (60 * 1000));

  // 计算相差秒数
  var leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  obj.second = Math.round(leave3 / 1000);
  return obj;
}
var ua = navigator.userAgent;
function isAndroid() {
  return ua.indexOf('Android') > 0;
}
function isIOS() {
  return /(iPhone|iPad|iPod)/i.test(ua);
}
/* eslint-disable */
function setupWebViewJavascriptBridge(callback) {
  if (window.WebViewJavascriptBridge) {
    return callback(WebViewJavascriptBridge);
  }
  if (window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(callback);
  }
  window.WVJBCallbacks = [callback];
  var WVJBIframe = document.createElement('iframe');
  WVJBIframe.style.display = 'none';
  WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(function () {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
}

/* eslint-disable */
function requestData(functionName, param, callback) {
  if (!callback) {
    callback = function callback() {};
  }
  var result = null;
  if (isAndroid()) {
    try {
      result = battlespace.call(functionName + (JSON.stringify(param) || ''));
      callback(result); //渲染
    } catch (e) {
      console.log('与安卓原生交互失败');
      console.error(e);
      return false;
    }
  } else if (isIOS()) {
    // ios
    // JavascriptBridge 调用
    try {
      setupWebViewJavascriptBridge(function (bridge) {
        bridge.callHandler(functionName, param, function (response) {
          result = response;
          callback(response);
          // return
        });
      });
    } catch (e) {
      console.log('与ios原生交互失败');
      console.error(e);
      return false;
    }
  }
}