"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "error-page",
    style: {
      backgroundImage: "url(" + _vm.bgImg + ")"
    }
  }, [_c("div", {
    staticClass: "error-icon"
  }, [_c("van-image", {
    attrs: {
      src: _vm.errorIcon
    }
  })], 1), _c("div", {
    staticClass: "error-tip"
  }, [_vm._v("网络竟然崩溃了")]), _c("div", {
    staticClass: "error-btn"
  }, [_c("van-button", {
    attrs: {
      type: "default",
      round: ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("立即刷新")])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;