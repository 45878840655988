"use strict";

var _interopRequireDefault = require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("E:/zy/yidahanggaotie/h5/node_modules/_@babel_runtime@7.25.7@@babel/runtime/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);
var routes = [{
  name: 'CardList',
  path: '/card-list',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/card-list'));
    });
  },
  meta: {
    title: '12306卡'
  }
}, {
  name: 'reservation',
  path: '/jh',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/reservation'));
    });
  },
  meta: {
    title: '预约'
  }
},
// --------------错误页 begin---------
{
  name: 'NoNetwork',
  path: '/no-network',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/error-pages/no-network'));
    });
  },
  meta: {
    title: '无网络'
  }
}, {
  path: '*',
  redirect: '/404'
}, {
  name: 'Error404',
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/error-pages/404'));
    });
  },
  meta: {
    title: '404'
  }
}
// --------------错误页 end---------
];

// add route path
// routes.forEach(route => {
//   route.path = route.path || '/' + (route.name || '')
// })

var router = new _vueRouter.default({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: routes
});
router.beforeEach(function (to, from, next) {
  var title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});
var _default = exports.default = router;